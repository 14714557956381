;(function ($) {
    $('body').on('blur', '.form-post-code', function () {
        var form = $($(this).parents('.post-code-input-group'));
        var country = form.find('.form-country');
        var city = form.find('.form-city');
        var state = form.find('.form-state');
        $.ajax({
            url: '/site/load-post-code-data',
            type: 'get',
            dataType: 'json',
            data: {
                zipCode: $(this).val(),
                countryCode: country.val()
            },
            success: function (response) {
                city.val(response.place_name);
                state.val(response.state_name);
            },
            error: function () {
                city.val('');
                state.val('');
            }
        });
    })
})(jQuery);