;(function($){
    var collapsible = $('.js-collapsible');

    if(collapsible.length) {
        var height = collapsible.height();

        if(height > 250) {
            collapsible.wrap('<div class="collapse-offset"><div class="collapse-content collapse-hidden"></div></div>');
            collapsible.closest('.collapse-offset').append('<a class="collapse-read-more btn" href="javascript:;">'+wwdh.t('Több')+'</a>');

            var btn = $('.collapse-read-more');
            var collapseOffset = $('.collapse-offset');
            var collapseContent = $('.collapse-content');

            btn.on('click', function() {
                $('html, body').animate({
                    scrollTop: collapseOffset.offset().top - 145
                }, 400);

                collapseContent.toggleClass('collapse-hidden');

                if(collapseContent.hasClass('collapse-hidden')) {
                    btn.text(wwdh.t('Több'));
                } else {
                    btn.text(wwdh.t('Kevesebb'));
                }
            });
        }
    }
})(jQuery);