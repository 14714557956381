;(function($, win){
    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function (event) {
            // On-page links
            if (
                win.location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                win.location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top - 110
                    }, 600, function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);

                    });
                }
            }
        });
})(jQuery, window);
