;(function($){
    $('body').on('change.billing-address-customer-toggle', '.billing-address-customer-toggle .billing-address-customer-type', function () {
        var $typeObj = $(this);
        var $container = $typeObj.parents('.billing-address-customer-toggle');
        var type = $typeObj.val();
        $container.find('[data-customer-type]').each(function (k, v) {
            if ($(v).data('customer-type') == type){
                $(v).show();
            } else {
                $(v).hide();
            }
        });
    });

    $('body').on('click', '.toLogin', function () {

        $("#checkoutauthloginform-email").val($("#checkoutauthguestform-email").val());
        $('a[href="#login-tabs-tablogin"]').trigger("click");

    });
})(jQuery);